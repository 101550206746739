import { SCol, SContainer, SIcon, SRow } from "@avalara/skylab-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import logo from "./Assets/Images/logo.png";
import "./index.css";
import { setResetSession } from "../src/App/sessionSlice";

const Header = (props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const redirectToLogin = () => {
		navigate("/login");
	};

	const redirectToLogout = () => {
		dispatch(setResetSession());
		navigate("/login");
	};

	return (
		<React.Fragment>
			<SContainer className="pad-top-sm">
				<div>
					<SRow>
						<SCol span="6" className="pad-bottom-none">
							<img
								src={logo}
								alt="avalara logo"
								className="image-align"
								width="92"
								height="20"
							/>
							<span className="text-md header-text divider-left">
								{" "}
								TTR ECMS Migration Utility
							</span>
						</SCol>
						<SCol span="6" className="pad-bottom-none">
							{props.isAuthenticated ? (
								<button
									className="right ghost pad-bottom-sm"
									onClick={redirectToLogout}
								>
									<SIcon
										name="user-circle-filled"
										className="pad-right-xs"
										aria-hidden="true"
									></SIcon>
									<span className="pad-bottom-xs">
										{" "}
										{props.user + "  "}
									</span>
								</button>
							) : (
								<button
									className="right ghost pad-bottom-sm"
									onClick={redirectToLogin}
								>
									<SIcon name="user-circle" aria-hidden="true"></SIcon>
								</button>
							)}
						</SCol>
					</SRow>
				</div>
			</SContainer>
			<div className="header-border"></div>
		</React.Fragment>
	);
};

export default Header;
