import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { SRow, SCol, SIcon, SDialog, SAlert } from "@avalara/skylab-react";
import classnames from "classnames";
import ValidateDialogSubComponent from "./ValidateDialogSubComponent";
import {
  selectAvataxAccount,
  selectECMSubscription,
  selectCertAccount,
  selectAvataxCompanies,
  selectCertCompanies,
  fetchExportAPI,
  fetchHasCertCompaniesAPI,
  fetchHasAvataxCompaniesAPI,
  fetchHasCertAccountAPI,
  fetchHasECMSubscriptionAPI,
  fetchIsAvataxAccountAPI,
  fetchMigrationAPI,
  selectAvataxCompaniesCount,
  selectCertCompaniesCount,
  selectIsExport,
  setIsExport,
  selectValidationError,
  setAvataxAccount,
  setECMSubscription,
  setCertAccount,
  setAvataxCompanies,
  setCertCompanies,
  setAvataxCompaniesCount,
  setCertCompaniesCount,
  selectEcmUsers,
  selectMissingUserCount,
  setValidationError,
  setMissingUsersCount,
  fetchHasMissingUsersAPI,
  setEcmUsers,
} from "../App/exportAccountSlice";
import "./export.css";

function ExportAccountDialog(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [companyId, setCompanyId] = useState();
  const [companyIdError, setCompanyIdError] = useState("");
  const [avaTaxId, setAvaTaxId] = useState("");
  const [avaIdError, setAvaIdError] = useState("");
  const [navigateToExport, setNavigateToExport] = useState(false);
  const [isCreateMissingCompanies, setIsCreateMissingCompanies] =
    useState(false);
  const [isCreateMissingUsers, setIsCreateUsers] = useState(false);

  const isExport = useSelector(selectIsExport);
  const AvataxAccount = useSelector(selectAvataxAccount);
  const EcmSubscription = useSelector(selectECMSubscription);
  const CertAccount = useSelector(selectCertAccount);
  const AvataxCompanies = useSelector(selectAvataxCompanies);
  const CertCompanies = useSelector(selectCertCompanies);
  const ecmUsers = useSelector(selectEcmUsers);

  const [validateClick, setValidationClick] = useState(false);
  const validationError = useSelector(selectValidationError);
  const avataxCompaniesCount = useSelector(selectAvataxCompaniesCount);
  const certCompaniesCount = useSelector(selectCertCompaniesCount);
  const missingUsersCount = useSelector(selectMissingUserCount);
  const exportClassName = classnames({ primary: true });

  const initialCleanup = useCallback(() => {
    setCompanyId("");
    setCompanyIdError("");
    setAvaTaxId("");
    setAvaIdError("");
    dispatch(setIsExport(false));
  }, [dispatch]);

  const clearState = () => {
    dispatch(setIsExport(false));
    dispatch(setAvataxAccount("initialValue"));
    dispatch(setECMSubscription("initialValue"));
    dispatch(setCertAccount("initialValue"));
    dispatch(setAvataxCompanies("initialValue"));
    dispatch(setCertCompanies("initialValue"));
    dispatch(setEcmUsers("initialValue"));
    dispatch(setAvataxCompaniesCount(0));
    dispatch(setCertCompaniesCount(0));
    dispatch(setMissingUsersCount(0));
    dispatch(setValidationError(""));
  };

  const handleChangeInput = (setter) => {
    return (event) => {
      if (event.target.value) {
        if (event.target.name === "companyId") {
          setCompanyIdError("");
        }
        if (event.target.name === "avaTaxId") {
          setAvaIdError("");
        }
      }
      setter(event.target.value);
    };
  };

  const handleCheckBoxChange = (e) => {
    if (e.target.name === "isCreateMissingCompanies") {
      setIsCreateMissingCompanies(!isCreateMissingCompanies);
    }
    if (e.target.name === "isCreateUsersRoles") {
      setIsCreateUsers(!isCreateMissingUsers);
    }
  };

  const handleValidate = async () => {
    let valid = true;
    clearState();
    if (!companyId) {
      setCompanyIdError("error");
      valid = false;
    }
    if (!avaTaxId && (isCreateMissingCompanies || isCreateMissingUsers)) {
      setAvaIdError("error");
      valid = false;
    }
    if (valid && (isCreateMissingCompanies || isCreateMissingUsers)) {
      setValidationClick(true);
      await dispatch(fetchIsAvataxAccountAPI(avaTaxId));
    }
  };

  const handleSubmitRequest = async () => {
    if (!(isCreateMissingCompanies || isCreateMissingUsers)) {
      dispatch(
        fetchExportAPI(
          0,
          companyId,
          isCreateMissingCompanies,
          isCreateMissingUsers,
          true
        )
      ).then(() => {
        setNavigateToExport(true);
        dispatch(fetchMigrationAPI());
      });
    } else {
      await handleValidate();
    }
  };

  const closeDialog = useCallback(() => {
    props.setIsExportDialogOpen(false);
    initialCleanup();
    clearState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (AvataxAccount === "complete" && avaTaxId) {
      dispatch(fetchHasECMSubscriptionAPI(avaTaxId));
    }
  }, [dispatch, AvataxAccount, avaTaxId]);

  useEffect(() => {
    if (EcmSubscription === "complete" && avaTaxId) {
      dispatch(fetchHasCertAccountAPI(avaTaxId));
    }
  }, [dispatch, EcmSubscription, avaTaxId]);

  useEffect(() => {
    if (CertAccount === "complete" && avaTaxId && companyId) {
      dispatch(fetchHasAvataxCompaniesAPI(avaTaxId, companyId));
    }
  }, [dispatch, CertAccount, avaTaxId, companyId]);

  useEffect(() => {
    if (AvataxCompanies === "complete" && avaTaxId && companyId) {
      dispatch(fetchHasCertCompaniesAPI(avaTaxId, companyId));
    }
  }, [dispatch, AvataxCompanies, avaTaxId, companyId]);

  useEffect(() => {
    if (CertCompanies === "complete" && avaTaxId && companyId) {
      dispatch(fetchHasMissingUsersAPI(avaTaxId, companyId));
    }
  }, [dispatch, CertCompanies, avaTaxId, companyId]);

  useEffect(() => {
    if (
      (isCreateMissingUsers &&
        isCreateMissingCompanies &&
        ecmUsers === "complete" &&
        CertCompanies === "complete") ||
      (isCreateMissingCompanies &&
        !isCreateMissingUsers &&
        CertCompanies === "complete") ||
      (!isCreateMissingCompanies &&
        isCreateMissingUsers &&
        ecmUsers === "complete")
    ) {
      if (avaTaxId && companyId) {
        dispatch(
          fetchExportAPI(
            avaTaxId,
            companyId,
            isCreateMissingCompanies,
            isCreateMissingUsers,
            true
          )
        ).then(() => {
          setNavigateToExport(true);
          dispatch(fetchMigrationAPI());
        });
      }
    }
  }, [
    dispatch,
    navigate,
    CertCompanies,
    avaTaxId,
    companyId,
    isCreateMissingCompanies,
    ecmUsers,
    isCreateMissingUsers,
  ]);

  useEffect(() => {
    if (navigateToExport) {
      closeDialog();
      navigate("/export");
    }
    return () => {
      setNavigateToExport(false);
    };
  }, [navigateToExport, navigate, closeDialog]);

  return (
    <>
      <SDialog
        id="exportAccountDialog"
        open={props.isExportDialogOpen}
        onS-dismiss={() =>{props.setIsExportDialogOpen(false); closeDialog()}}
        aria-labelledby="dialog-title"
        aria-modal="true"
        className="export-dialog"
      >
        <div slot="header"> Export </div>
        <div slot="body">
          <SRow>
            <SCol span="12" className="pad-bottom-sm">
              <label htmlFor="companyId" className="required">Company Id</label>
              <input
                id="companyId"
                name="companyId"
                type="text"
                value={companyId}
                onChange={handleChangeInput(setCompanyId)}
                className={companyIdError}
              />
              {companyIdError ? (
                <div className="input-msg">
                  <SIcon name="alert-circle-filled" aria-hidden="true" />
                  <span className="top-xs">Company Id is required</span>
                </div>
              ) : null}
            </SCol>
          </SRow>
          <SRow>
            <SCol className="pad-bottom-sm">
              <input
                type="checkbox"
                id="isCreateMissingCompanies"
                name="isCreateMissingCompanies"
                checked={
                  isCreateMissingCompanies ? isCreateMissingCompanies : false
                }
                onChange={handleCheckBoxChange}
              />
              <label htmlFor="isCreateMissingCompanies">
                Create missing companies
              </label>
            </SCol>
          </SRow>
          <SRow>
            <SCol className="pad-bottom-sm">
              <input
                type="checkbox"
                id="isCreateUsersRoles"
                name="isCreateUsersRoles"
                checked={isCreateMissingUsers ? isCreateMissingUsers : false}
                onChange={handleCheckBoxChange}
              />
              <label htmlFor="isCreateUsersRoles">Create users & roles</label>
            </SCol>
          </SRow>

          <div
            className={
              !(isCreateMissingCompanies || isCreateMissingUsers)
                ? "hidden"
                : "block pad-bottom-sm"
            }
          >
            <SRow>
              <SCol className="pad-bottom-sm">
                <label htmlFor="avaTaxId" className="required">Avatax Account Id</label>
                <input
                  id="avaTaxId"
                  name="avaTaxId"
                  type="text"
                  value={avaTaxId}
                  onChange={handleChangeInput(setAvaTaxId)}
                  className={avaIdError}
                />
                {avaIdError ? (
                  <div className="input-msg">
                    <SIcon name="alert-circle-filled" aria-hidden="true" />
                    <span className="top-xs">
                      Avatax Account Id is required
                    </span>
                  </div>
                ) : null}
              </SCol>
            </SRow>

            {validateClick ? (
              <>
                <SRow className="pad-bottom-sm">
                  <ValidateDialogSubComponent
                    flag={AvataxAccount}
                    initialText="Avatax Account"
                  />
                </SRow>
                <SRow className="pad-bottom-sm">
                  <ValidateDialogSubComponent
                    flag={EcmSubscription}
                    initialText="ECM Pro Subscription"
                  />
                </SRow>
                <SRow className="pad-bottom-sm">
                  <ValidateDialogSubComponent
                    flag={CertAccount}
                    initialText="CertCapture Account"
                  />
                </SRow>
                <SRow className="pad-bottom-sm">
                  <ValidateDialogSubComponent
                    flag={AvataxCompanies}
                    initialText="Avatax Companies"
                  />
                </SRow>
                <SRow className="pad-bottom-sm">
                  <ValidateDialogSubComponent
                    flag={CertCompanies}
                    initialText="Certcapture Companies"
                  />
                </SRow>
                {isCreateMissingUsers ? (
                  <SRow className="pad-bottom-sm">
                    <ValidateDialogSubComponent
                      flag={ecmUsers}
                      initialText="Users & roles"
                    />
                  </SRow>
                ) : null}
              </>
            ) : null}
            {validationError ? (
              <SRow>
                <SCol className="pad-left-sm">
                  {validationError !== "" && validationError !== "success" ? (
                    <SAlert status="error" noDismiss>
                      <div>{validationError}</div>
                    </SAlert>
                  ) : validationError === "success" &&
                    (avataxCompaniesCount !== 0 ||
                      certCompaniesCount !== 0 ||
                      missingUsersCount !== 0) ? (
                    <SAlert status="success" noDismiss>
                      {avataxCompaniesCount !== 0 ? (
                        <div>
                          {avataxCompaniesCount > 1
                            ? `Found ${avataxCompaniesCount} Avatax Companies.`
                            : `Found ${avataxCompaniesCount} Avatax Company.`}
                        </div>
                      ) : null}
                      {certCompaniesCount !== 0 ? (
                        <div>
                          {certCompaniesCount > 1
                            ? `Found ${certCompaniesCount} CertCapture Companies.`
                            : `Found ${certCompaniesCount} CertCapture Company.`}
                        </div>
                      ) : null}
                      {missingUsersCount !== 0 ? (
                        <div>
                          {missingUsersCount > 1
                            ? `Found ${missingUsersCount} Users.`
                            : `Found ${missingUsersCount} User.`}
                        </div>
                      ) : null}
                    </SAlert>
                  ) : null}
                </SCol>
              </SRow>
            ) : null}
          </div>
        </div>

        <div slot="footer">
          <button className="secondary" onClick={closeDialog}>
            Cancel
          </button>
          <button
            className={isExport ? "primary loading" : exportClassName}
            name="submit"
            disabled={isExport}
            onClick={handleSubmitRequest}
          >
            Submit
          </button>
        </div>
      </SDialog>
    </>
  );
}
export default ExportAccountDialog;
