import { createSlice } from "@reduxjs/toolkit";

export const sessionSlice = createSlice({
	name: "session",
	initialState: {
		isLoading: false,
		isAuthenticated: false,
		userName: null,
		firstName: null,
		lastName: null,
		email: null,
		isFirstLogin: false,
		accessToken: null,
	},
	reducers: {
		setLoading: (state, action) => {
			state.isLoading = action.payload;
		},
		setData: (state, action) => {
			state.isAuthenticated = action.payload.isAuthenticated;
			state.userName = action.payload.username;
			state.firstName = action.payload.firstName;
			state.lastName = action.payload.lastName;
			state.email = action.payload.email;
			state.accessToken = action.payload.accessToken;
			state.isFirstLogin = action.payload.isFirstLogin;
			state.accessTokenExpiresAt = action.payload.accessTokenExpiresAt;
		},
		setResetSession: (state, action) => {
			state.isAuthenticated = false;
			state.userName = null;
			state.firstName = null;
			state.lastName = null;
			state.email = null;
			state.accessToken = null;
			state.accessTokenExpiresAt = 0;
		},
	},
});

export const { setLoading, setData, setResetSession } = sessionSlice.actions;

export const selectSession = state => {
	return state.session;
};

export const selectAccessToken = state => {
	return state.session.accessToken;
};

export default sessionSlice.reducer;
