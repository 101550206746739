import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import toast from "../Shared/toast";
export const exportAccountSlice = createSlice({
  name: "exportAccount",
  isExportLoading: false,
  isLoading: false,
  pageData: [],
  initialState: {
    avataxAccount: "initialValue",
    ecmSubscription: "initialValue",
    certAccount: "initialValue",
    avataxCompanies: "initialValue",
    certCompanies: "initialValue",
    ecmUsers: "initialValue",
    avataxCompaniesCount: 0,
    certCompaniesCount: 0,
    missingUserCount: 0,
    validationError: "",
    isExport: false,
  },
  isExportDialogOpen: false,
  sortColumn: ["companyId", true],

  reducers: {
    setAvataxAccount: (state, action) => {
      state.avataxAccount = action.payload;
    },
    setECMSubscription: (state, action) => {
      state.ecmSubscription = action.payload;
    },
    setCertAccount: (state, action) => {
      state.certAccount = action.payload;
    },
    setAvataxCompanies: (state, action) => {
      state.avataxCompanies = action.payload;
    },
    setCertCompanies: (state, action) => {
      state.certCompanies = action.payload;
    },
    setEcmUsers: (state, action) => {
      state.ecmUsers = action.payload;
    },
    setMissingUsersCount: (state, action) => {
      state.missingUserCount = action.payload;
    },
    setIsExportLoading: (state, action) => {
      state.isExportLoading = action.payload;
    },
    setPageData: (state, action) => {
      state.pageData = action.payload;
    },
    setPageToNull: (state) => {
      state.pageData = [];
    },
    setSortColumn: (state, action) => {
      state.sortColumn = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setCertCompaniesCount: (state, action) => {
      state.certCompaniesCount = action.payload;
    },
    setValidationError: (state, action) => {
      state.validationError = action.payload;
    },
    setIsExport: (state, action) => {
      state.isExport = action.payload;
    },
    setAvataxCompaniesCount: (state, action) => {
      state.avataxCompaniesCount = action.payload;
    },
    setIsExportDialogOpen: (state, action) => {
      state.isExportDialogOpen = action.payload;
    },
  },
});

export const {
  setAvataxAccount,
  setECMSubscription,
  setCertAccount,
  setAvataxCompanies,
  setAvataxCompaniesCount,
  setCertCompanies,
  setEcmUsers,
  setMissingUsersCount,
  setIsExportLoading,
  setPageData,
  setPageToNull,
  setSortColumn,
  setIsLoading,
  setCertCompaniesCount,
  setValidationError,
  setIsExport,
  clearState,
  setIsExportDialogOpen,
} = exportAccountSlice.actions;

export const selectAvataxAccount = (state) => {
  return state.exportAccount.avataxAccount;
};
export const selectECMSubscription = (state) => {
  return state.exportAccount.ecmSubscription;
};

export const selectCertAccount = (state) => {
  return state.exportAccount.certAccount;
};
export const selectAvataxCompanies = (state) => {
  return state.exportAccount.avataxCompanies;
};
export const selectCertCompanies = (state) => {
  return state.exportAccount.certCompanies;
};

export const selectEcmUsers = (state) => {
  return state.exportAccount.ecmUsers;
};
export const selectMissingUserCount = (state) => {
  return state.exportAccount.missingUserCount;
};

export const selectIsExportLoading = (state) => {
  return state.exportAccount.isExportLoading;
};
export const selectPageData = (state) => {
  return state.exportAccount.pageData;
};

export const selectSortColumn = (state) => {
  return state.exportAccount.sortColumn;
};
export const selectIsLoading = (state) => {
  return state.exportAccount.isLoading;
};
export const selectValidationError = (state) => {
  return state.exportAccount.validationError;
};
export const selectAvataxCompaniesCount = (state) => {
  return state.exportAccount.avataxCompaniesCount;
};

export const selectCertCompaniesCount = (state) => {
  return state.exportAccount.certCompaniesCount;
};

export const selectIsExport = (state) => {
  return state.exportAccount.isExport;
};

export const selectIsExportDialogOpen = (state) => {
  return state.exportAccount.isExportDialogOpen;
};

const [showToast] = toast();
export const fetchMigrationAPI = () => async (dispatch, getState) => {
  const { isLoading } = getState().exportAccount;
  if (isLoading) {
    return;
  }
  dispatch(setIsLoading(true));
  const response = await axios.get(
    `//${process.env.REACT_APP_API_HOST}/api/Migration/migrations`,
    { withCredentials: true }
  );

  if (response?.data) {
    dispatch(setPageData(response.data));
  }
  dispatch(setIsLoading(false));
};

export const fetchExportAPI =
  (
    avaTaxId,
    companyId,
    isCreateMissingCompanies,
    createMissingUsers,
    suppressNewUserEmail = true
  ) =>
  async (dispatch, getState) => {
    const { isExportLoading } = getState().exportAccount;
    if (isExportLoading) {
      return;
    }
    dispatch(setIsExportLoading(true));
    const response = await axios.get(
      `//${process.env.REACT_APP_API_HOST}/api/Migration/export/${avaTaxId}/${companyId}/${isCreateMissingCompanies}/${createMissingUsers}/${suppressNewUserEmail}`
    );
    if (response?.status === 200) {
      dispatch(fetchMigrationAPI);
      showToast("success", "Your request for export was submitted.");
    } else {
      showToast(
        "error",
        "We have encountered an issue while submitting your request. Please try again later."
      );
    }
    dispatch(setIsExportLoading(false));
  };

export const fetchHasCertCompaniesAPI =
  (avaTaxId, companyId) => async (dispatch, getState) => {
    const { certCompanies } = getState().exportAccount;
    if (certCompanies === "loading") {
      return;
    }
    dispatch(setCertCompanies("loading"));
    await axios
      .get(
        `//${process.env.REACT_APP_API_HOST}/api/Account/has-missing-certcapture-companies/${avaTaxId}/${companyId}`
      )
      .then((response) => {
        if (response.data !== null) {
          dispatch(setCertCompaniesCount(response?.data?.length));
          dispatch(setCertCompanies("complete"));
          dispatch(setValidationError("success"));
        } else {
          setIsExport(false);
          dispatch(setCertCompanies("error"));
          dispatch(setValidationError("No CertCapture companies were found."));
        }
      });
  };

export const fetchHasAvataxCompaniesAPI =
  (avaTaxId, companyId) => async (dispatch, getState) => {
    const { avataxCompanies } = getState().exportAccount;
    if (avataxCompanies === "loading") {
      return;
    }
    dispatch(setAvataxCompanies("loading"));
    await axios
      .get(
        `//${process.env.REACT_APP_API_HOST}/api/Account/has-missing-avatax-companies/${avaTaxId}/${companyId}`
      )
      .then(async (response) => {
        if (response?.data !== null) {
          dispatch(setAvataxCompaniesCount(response?.data?.length));
          dispatch(setAvataxCompanies("complete"));
          dispatch(setValidationError("success"));
        } else {
          dispatch(setIsExport(false));
          dispatch(setAvataxCompanies("error"));
          dispatch(setValidationError("No Avatax companies were found."));
        }
      });
  };

export const fetchHasCertAccountAPI =
  (avaTaxId) => async (dispatch, getState) => {
    const { certAccount } = getState().exportAccount;
    if (certAccount === "loading") {
      return;
    }
    dispatch(setCertAccount("loading"));
    await axios
      .get(
        `//${process.env.REACT_APP_API_HOST}/api/Account/has-certcapture-account/${avaTaxId}`
      )
      .then(async (response) => {
        if (response?.data !== null) {
          dispatch(setCertAccount("complete"));
        } else {
          dispatch(setIsExport(false));
          dispatch(setValidationError("No CertCapture account was found."));
          dispatch(setCertAccount("error"));
        }
      });
  };

export const fetchHasECMSubscriptionAPI =
  (avaTaxId) => async (dispatch, getState) => {
    const { ecmSubscription } = getState().exportAccount;
    if (ecmSubscription === "loading") {
      return;
    }
    dispatch(setECMSubscription("loading"));
    await axios
      .get(
        `//${process.env.REACT_APP_API_HOST}/api/Account/has-ecm-subscription/${avaTaxId}`
      )
      .then(async (response) => {
        if (response?.data) {
          dispatch(setECMSubscription("complete"));
        } else {
          dispatch(setIsExport(false));
          dispatch(
            setValidationError(
              "No ECM Pro or ECM Premium subscription was found."
            )
          );
          dispatch(setECMSubscription("error"));
        }
      });
  };

export const fetchIsAvataxAccountAPI =
  (avaTaxId) => async (dispatch, getState) => {
    const { avataxAccount } = getState().exportAccount;
    if (avataxAccount === "loading") {
      return;
    }
    dispatch(setAvataxAccount("loading"));
    await axios
      .get(
        `//${process.env.REACT_APP_API_HOST}/api/Account/avatax-account/${avaTaxId}`
      )
      .then(async (response) => {
        if (response?.data) {
          dispatch(setAvataxAccount("complete"));
        } else {
          dispatch(setIsExport(false));
          dispatch(setValidationError("No Avatax account was found."));
          dispatch(setAvataxAccount("error"));
        }
      });
  };

export const fetchHasMissingUsersAPI =
  (avaTaxId, companyId) => async (dispatch, getState) => {
    const { ecmUsers } = getState().exportAccount;
    if (ecmUsers === "loading") {
      return;
    }
    dispatch(setEcmUsers("loading"));
    await axios
      .get(
        `//${process.env.REACT_APP_API_HOST}/api/Account/has-missing-users/${avaTaxId}/${companyId}`
      )
      .then(async (response) => {
        if (response?.data !== null) {
          dispatch(setMissingUsersCount(response?.data?.length));
          dispatch(setEcmUsers("complete"));
          dispatch(setValidationError("success"));
        } else {
          dispatch(setIsExport(false));
          dispatch(setEcmUsers("error"));
          dispatch(setValidationError("No users are found."));
        }
      });
  };

export default exportAccountSlice.reducer;
