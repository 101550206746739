import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DataGrid, gridHeader } from "@certcapture/react-components";
import { SRow, SCol, SIcon, SLoader, STag } from "@avalara/skylab-react";
import toast from "../Shared/toast";
import { selectSession } from "../App/sessionSlice";
import ExportDetailDialogGrid from "./ExportDetailDialogGrid";
import ValidateDialog from "./ValidateDialog";
import ExportAccountDialog from "./ExportAccountDialog";
import {
	fetchMigrationAPI,
	selectIsLoading,
	selectPageData,
	setPageData,
	selectSortColumn,
	setSortColumn,
} from "../App/exportAccountSlice";
import { getFormattedDate } from "../Shared/Utils";
import axios from "../axios";

const ExportGrid = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const pageData = useSelector(selectPageData, shallowEqual);
	const loading = useSelector(selectIsLoading);
	const [exportDetail, setExportDetail] = useState([]);
	const [isExportDetailDialogOpen, setIsExportDetailDialogOpen] =
		useState(false);
	const [isValidateDialogOpen, setIsValidateDialogOpen] = useState(false);
	const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
	const [isDownloading, setIsisDownloading] = useState(false);
	const [rowId, setRowId] = useState();
	const [showToast] = toast();
	const session = useSelector(selectSession, shallowEqual);
	let sortColumn = useSelector(selectSortColumn, shallowEqual);
	if (!sortColumn) {
		sortColumn = ["companyId", true];
	}
	useEffect(() => {
		if (!session.isAuthenticated) {
			navigate("/login");
			showToast("error", "Please login to access this page.");
		}
		getMigrations();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const getMigrations = useCallback(async () => {
		dispatch(fetchMigrationAPI());
	}, [dispatch]);

	const handleDetail = (row) => {
		setExportDetail(row.exportedCompanies);
		setIsExportDetailDialogOpen(true);
	};

	const sortById = function (order) {
		let sortArray = [...pageData];
		if (order) {
			const sortedAsc = sortArray.sort((a, b) =>
				a.companyName > b.companyName ? 1 : -1
			);
			dispatch(setPageData(sortedAsc));
		} else {
			const sortedDesc = sortArray.sort((a, b) =>
				a.companyName > b.companyName ? -1 : 1
			);
			dispatch(setPageData(sortedDesc));
		}
	};

	const sortByCompanyName = function (order) {
		let sortArray = [...pageData];
		if (order) {
			const sortedAsc = sortArray.sort((a, b) =>
				a.companyName > b.companyName ? 1 : -1
			);
			dispatch(setPageData(sortedAsc));
		} else {
			const sortedDesc = sortArray.sort((a, b) =>
				a.companyName > b.companyName ? -1 : 1
			);
			dispatch(setPageData(sortedDesc));
		}
	};

	const sortByCreatedOn = function (order) {
		let sortArray = [...pageData];
		if (order) {
			const sortedAsc = sortArray.sort(
				(a, b) => Number(a.createdOn) - Number(b.createdOn)
			);
			dispatch(setPageData(sortedAsc));
		} else {
			const sortedDesc = sortArray.sort(
				(a, b) => Number(b.createdOn) - Number(a.createdOn)
			);
			dispatch(setPageData(sortedDesc));
		}
	};

	const sortByStatus = function (order) {
		let sortArray = [...pageData];
		if (order) {
			const sortedAsc = sortArray.sort((a, b) =>
				a.processStatus > b.processStatus ? 1 : -1
			);
			dispatch(setPageData(sortedAsc));
		} else {
			const sortedDesc = sortArray.sort((a, b) =>
				a.processStatus < b.processStatus ? 1 : -1
			);
			dispatch(setPageData(sortedDesc));
		}
	};

	async function sortFunction(e) {
		const column = e.detail.id;
		const order = sortColumn[0] === column ? !sortColumn[1] : true;
		dispatch(setSortColumn([column, order]));
		if (column === "companyId") {
			sortById(order);
		} else if (column === "companyName") {
			sortByCompanyName(order);
		} else if (column === "createdOn") {
			sortByCreatedOn(order);
		} else if (column === "processStatus") {
			sortByStatus(order);
		}
	}

	const downloadFile =async id =>{
		setRowId(id)
		setIsisDownloading(true);
		await axios
        .get(
            `//${process.env.REACT_APP_API_HOST}/api/Migration/migration-result/${id}`,
            { withCredentials: true, responseType: "blob" }
        )
        .then(response => {
            if (!response.headers["content-type"].includes("application/zip")) {
                showToast("error", "Failed to download. Please try again later");
                return;
            }
            const blob = new Blob([response.data], { type: response.data.type });
            const url = window.URL.createObjectURL(blob);
			const filename = response.headers['content-disposition'].split(';')
			.find(n => n.includes('filename=')).replace('filename=', '').trim();
            const link = document.createElement("a");
            link.href = url;
			link.download = filename;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        });
		setIsisDownloading(false);
	}

	const headerColumns = [
		gridHeader(
			"Company Id",
			"companyId",
			null,
			null,
			null,
			null,
			null,
			sortFunction,
			"companyId"
		),
		gridHeader(
			"Company Name",
			"companyName",
			null,
			null,
			null,
			null,
			null,
			sortFunction,
			"companyName"
		),

		gridHeader(
			"Export Statistics",
			"companiesExportedCount",
			(value, row) => (
				<React.Fragment>
					<button
						className="ghost-blue"
						onClick={() => handleDetail(row)}
					>
						{value}
					</button>
				</React.Fragment>
			),
			null,
			null,
			null,
			null,
			null,
			"companiesExportedCount"
		),
		gridHeader(
			"Created On",
			"createdOn",
			(value) => getFormattedDate(value, false),
			null,
			"",
			null,
			null,
			null,
			"createdOn"
		),

		gridHeader(
			"Status",
			"processStatus",
			(value, row) => (
				<React.Fragment>
					<STag
					   aria-label={row.processStatus}
						color={
							row.processStatus === "Succeeded"
								? "green-dark"
								: row.processStatus === "In Progress"
								? "yellow-medium"
								: "red-dark"
						}
					>
						<span slot="content"> {row.processStatus}</span>
					</STag>
				</React.Fragment>
			),
			null,
			null,
			null,
			null,
			sortFunction,
			"processStatus"
		),

		gridHeader(
			"",
			"",
			(value, row) => (
				<React.Fragment>
					{row.processStatus === "Succeeded" ? (
						<button
						className={`${isDownloading && row.id === rowId ? "ghost-blue loading": "ghost-blue"}`}
						// disabled={`${isDownloading ? true: false}`}
						onClick={() => downloadFile(row.id)}
					>
						<SIcon name="download" aria-hidden="true"/>
					</button>
					) : null}
				</React.Fragment>
			),
			"text-right",
			"text-right"
		),
	];

	const getRowKey = (row) => {
		return row.id;
	};

	const table = loading ? (
		<React.Fragment>
			<div className="flex dl-flex-fill-height dl-flex-center">
				<h3>Loading ...</h3>
				<SLoader
					id="page-loader"
					class="medium"
					aria-live="polite"
					loading
				/>
			</div>
		</React.Fragment>
	) : (
		<React.Fragment>
			{pageData?.length > 0 ? (
				<s-table-container class="margin-top-xl th-with-filter">
					<DataGrid
						columns={headerColumns}
						rows={pageData}
						getKey={getRowKey}
						loading
						inContainer
						sortColumn={sortColumn}
					/>
				</s-table-container>
			) : (
				<h3>No export records available.</h3>
			)}
		</React.Fragment>
	);

	const filterRow = (
		<>
			<SRow className="align-items-end margin-top-lg">
				<SCol class="flex justify-content-end pad-bottom-none">
					<button
						className="secondary icon-leading"
						onClick={() => getMigrations()}
					>
						<SIcon name="refresh" aria-hidden="true"/>
						Refresh
					</button>
					<button
						className="secondary icon-leading"
						onClick={() => setIsExportDialogOpen(true)}
					>
						<SIcon name="export" aria-hidden="true"/>
						Export
					</button>
					<button
						className="secondary icon-leading"
						onClick={() => setIsValidateDialogOpen(true)}
					>
						<SIcon name="security-check" aria-hidden="true"/>
						Account Verification
					</button>
				</SCol>
			</SRow>
		</>
	);

	return (
		<React.Fragment>
			{filterRow}
			{table}
			{isValidateDialogOpen && !isExportDialogOpen ? (
				<ValidateDialog
					isValidateDialogOpen={isValidateDialogOpen}
					setIsValidateDialogOpen={setIsValidateDialogOpen}
				/>
			) : null}
			{isExportDialogOpen ? (
				<ExportAccountDialog
					isExportDialogOpen={isExportDialogOpen}
					setIsExportDialogOpen={setIsExportDialogOpen}
				/>
			) : null}

			{pageData?.length > 0 ? (
				<ExportDetailDialogGrid
					exportDetail={exportDetail}
					setIsExportDetailDialogOpen={setIsExportDetailDialogOpen}
					isExportDetailDialogOpen={isExportDetailDialogOpen}
				/>
			) : null}
			
		</React.Fragment>
	);
};
export default ExportGrid;
