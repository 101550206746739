import axios from "axios";
import store from "./App/store";

const baseURL = `//${process.env.REACT_APP_API_HOST}/api`;
const axiosInstance = axios.create({
	baseURL: baseURL,
	headers: {
		"Content-Type": "application/json",
	},
});

axiosInstance.interceptors.request.use(
	(request) => {
		const { session } = store.getState();
		const token = session.accessToken;
		request.headers.Authorization = token ? `Bearer ${token}` : "";
		return request;
	},
	async (error) => {
		return Promise.reject(error);
	}
);

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		return error.response;
	}
);

export default axiosInstance;
