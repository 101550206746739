import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuiFooter, SToasts, SContainer } from "@avalara/skylab-react";
import { shallowEqual, useSelector } from "react-redux";
import Header from "./Header";
import ExportGrid from "./Export/ExportGrid";
import Login from "./Login/Login";
import ChangePassword from "./Login/ChangePassword";
import "./index.css";
import { selectSession } from "./App/sessionSlice";

function App() {
	const session = useSelector(selectSession, shallowEqual);
	const user = `${session.firstName} ${session.lastName}  `;
	return (
		<>
			<BrowserRouter>
				<Header isAuthenticated={session.isAuthenticated} user={user} />
				<SContainer>
					<Routes>
						<Route path="/" element={<Login />} />
						<Route path="/login" element={<Login />} />
						<Route
							path="/change-password"
							element={<ChangePassword />}
						/>
						<Route path="/export" element={<ExportGrid />} />
					</Routes>
					<SToasts  role="status"/>
				</SContainer>
				<AuiFooter />
			</BrowserRouter>
		</>
	);
}

export default App;
