import React, { useState } from "react";
import { SRow, SCol, SIcon, SBox, SAlert } from "@avalara/skylab-react";
import axios from "../axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setData } from "../App/sessionSlice";

const Login = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [userName, setUserName] = useState("");
	const [userNameError, setUserNameError] = useState("");
	const [password, setPassword] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [isUnauthorized, setIsUnauthorized] = useState(false);

	const handleUsername = (value) => {
		setUserName(value);
		setUserNameError("");
		setIsUnauthorized(false);
	};

	const handlePassword = (value) => {
		setPassword(value);
		setPasswordError("");
		setIsUnauthorized(false);
	};

	const onLogin = async () => {
		if (!userName) {
			setUserNameError("error");
		}
		if (!password) {
			setPasswordError("error");
		}
		if (!userName || !password) {
			setUserNameError("error");
			setPasswordError("error");
			return;
		}

		await axios
			.post(
				`//${process.env.REACT_APP_API_HOST}/api/Login/login?loginUserName=${userName}&loginPassword=${password}`
			)
			.then(async (response) => {
				if (response.status === 200) {
					response.data.isAuthenticated = true;
					await dispatch(setData(response.data));
					if (response.data.isFirstLogin) {
						navigate("/change-password", {
							state: { userName: response.data.username },
						});
					} else {
						navigate("/export");
					}
				} else if (response.status === 401) {
					setIsUnauthorized(true);
				}
			});
	};

	return (
		<React.Fragment>
			<SRow>
				<SCol center="sm, md, lg, xl" span="3" className="margin-top-xl">
					<SBox>
						<SRow>
							<SCol>
								<label htmlFor="userName" className="required">Username</label>
								<input
									name="userName"
									id="userName"
									type="text"
									value={userName}
									className={userNameError}
									onChange={(e) =>
										handleUsername(e.target.value)
									}
								/>
								{userNameError ? (
									<div className="input-msg">
										<SIcon
											name="alert-circle-filled"
											aria-hidden="true"
										/>
										<span className="top-xs">
											Username is required
										</span>
									</div>
								) : null}
							</SCol>
						</SRow>
						<SRow>
							<SCol>
								<label htmlFor="password" className="required">Password</label>
								<input
									name="password"
									id="password"
									type="password"
									value={password}
									className={passwordError}
									onChange={(e) =>
										handlePassword(e.target.value)
									}
								/>
								{passwordError.length > 0 ? (
									<div className="input-msg">
										<SIcon
											name="alert-circle-filled"
											aria-hidden="true"
										/>
										<span className="top-xs">
											Password is required
										</span>
									</div>
								) : null}
							</SCol>
							<SCol hidden={!isUnauthorized}>
								<SAlert
									id="error-alert"
									status="error"
									noDismiss
								>
									Username and/or Password is incorrect
								</SAlert>
							</SCol>
						</SRow>
						<SRow>
							<SCol className="text-right">
								<button
									name="signIn"
									onClick={onLogin}
									className="primary"
								>
									Sign in
								</button>
							</SCol>
						</SRow>
					</SBox>
				</SCol>
			</SRow>
		</React.Fragment>
	);
};
export default Login;
