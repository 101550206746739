import React, { useState } from "react";
import { SContainer, SRow, SCol, SIcon, SBox, SAlert } from "@avalara/skylab-react";
import axios from "../axios";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { selectSession } from "../App/sessionSlice";
import { shallowEqual, useSelector } from "react-redux";
import toast from "../Shared/toast";

const ChangePassword = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [showToast] = toast();
	const [confirmPassword, setConfirmPassword] = useState("");
	const [confirmPasswordError, setConfirmPasswordError] = useState("");
	const [password, setPassword] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [passwordMatchError, setPasswordMatchError] = useState(false);
	const [passwordLengthError, setPasswordLengthError] = useState(false);
	const session = useSelector(selectSession, shallowEqual);

	useEffect(() => {
		if (!session.isAuthenticated || !session.isFirstLogin) {
			navigate("/login");
			showToast("error", "Please login to access this page.");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onChangePassword = async () => {
		if (!confirmPassword) {
			setConfirmPasswordError("error");
		}
		if (!password) {
			setPasswordError("error");
		}
		if (!confirmPassword || !password) {
			return;
		}
		if (confirmPassword !== password) {
			setPasswordMatchError(true);
			return;
		}
		if (confirmPassword.length < 8) {
			setPasswordLengthError(true);
			return;
		}
		const response = await axios.post(
			`//${process.env.REACT_APP_API_HOST}/api/Login/change-password?userName=${location?.state?.userName}&newPassword=${password}`
		);
		if (response && response.status === 200) {
			showToast("success", "Password has been updated.");
			navigate("/login");
		}
	};

	const onCancel = () => {
		navigate("/login");
	};

	return (
		<>
			<SContainer>
				<SRow>
					<SCol
						span="4"
						center="sm, md, lg, xl"
						className="margin-top-lg"
					>
						<SBox>
							<SRow>
								<SCol>
									<SAlert status="info" noDismiss>
										{" "}
										It is mandatory to change the password
										when you log in to the utility for the
										first time. Click Cancel to go back to
										the login page.
									</SAlert>
								</SCol>
								<SCol>
									<label htmlFor="userName">Username</label>
									<input
										name="userName"
										id="userName"
										type="text"
										value={location?.state?.userName}
										readOnly
									/>
								</SCol>
							</SRow>
							<SRow>
								<SCol>
									<label htmlFor="newPassword" className="required">
										New password
									</label>
									<input
										name="newPassword"
										id="newPassword"
										type="password"
										value={password}
										className={passwordError}
										onChange={(e) => {
											setPassword(e.target.value);
											setPasswordError("");
											setPasswordMatchError(false);
											setPasswordLengthError(false);
										}}
									/>
									{passwordError.length > 0 ? (
										<div className="input-msg">
											<SIcon
												name="alert-circle-filled"
												aria-hidden="true"
											/>
											<span className="top-xs">
												New password is mandatory
											</span>
										</div>
									) : null}
								</SCol>
							</SRow>
							<SRow>
								<SCol>
									<label htmlFor="confirmPassword" className="required">
										Confirm password
									</label>
									<input
										name="confirmPassword"
										id="confirmPassword"
										type="password"
										value={confirmPassword}
										className={confirmPasswordError}
										onChange={(e) => {
											setConfirmPassword(e.target.value);
											setConfirmPasswordError("");
											setPasswordMatchError(false);
											setPasswordLengthError(false);
										}}
									/>
									{confirmPasswordError.length > 0 ? (
										<div className="input-msg">
											<SIcon
												name="alert-circle-filled"
												aria-hidden="true"
											/>
											<span className="top-xs">
												Confirm password is mandatory
											</span>
										</div>
									) : null}
								</SCol>
								{passwordMatchError ? (
									<SCol>
										<SAlert status="error" noDismiss>
											{" "}
											Passwords do not match
										</SAlert>
									</SCol>
								) : null}
								{passwordLengthError ? (
									<SCol>
										<SAlert status="error" noDismiss>
											{" "}
											Your password must be at least 8
											characters long
										</SAlert>
									</SCol>
								) : null}
							</SRow>
							<SRow>
								<SCol className="text-right">
									<button
										className="secondary"
										name="Cancel"
										onClick={onCancel}
									>
										Cancel
									</button>
									<button
										name="changePassword"
										onClick={onChangePassword}
										className="primary"
									>
										Change password
									</button>
								</SCol>
							</SRow>
						</SBox>
					</SCol>
				</SRow>
			</SContainer>
		</>
	);
};
export default ChangePassword;
