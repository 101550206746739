import React from "react";
import { SIcon, SLoader } from "@avalara/skylab-react";

import "./export.css";

const ValidateDialogSubComponent = (props) => {
  return props.flag === "initialValue" || props.flag === "loading" ? (
    props.flag === "loading" ? (
      <div className="pad-left-sm inline-flex align-items-center">
        <SIcon
          name="check-circle"
          className="pad-top-xs pad-bottom-xs medium flex-wrap"
          aria-hidden="true"
        />
        <span className="pad-right-sm pad-left-xs text-sm-strong">
          {props.initialText}
        </span>
        <SLoader loading className="small" aria-live="polite"/>
      </div>
    ) : (
      <div className="pad-left-sm inline-flex align-items-center">
        <SIcon
          name="check-circle"
          className="pad-top-xs pad-bottom-xs medium flex-wrap"
          aria-hidden="true"
        />
        <span className="pad-right-sm pad-left-xs text-sm-strong">
          {props.initialText}
        </span>
      </div>
    )
  ) : props.flag === "error" ? (
    <div className="pad-left-sm inline-flex align-items-center">
      <SIcon
        name="close-circle-filled"
        className="pad-top-xs pad-bottom-xs medium flex-wrap"
        id="failure-color"
        aria-hidden="true"
      />
      <span className="pad-right-sm pad-left-xs text-sm-strong">
        {props.initialText}
      </span>
    </div>
  ) : (
    <div className="pad-left-sm inline-flex align-items-center">
      <SIcon
        name="check-circle-filled"
        className="pad-top-xs pad-bottom-xs medium flex-wrap"
        id="success-color"
        aria-hidden="true"
      />
      <span className="pad-right-sm pad-left-xs text-sm-strong">
        {props.initialText}
      </span>
    </div>
  );
};

export default React.memo(ValidateDialogSubComponent);
