export function getFormattedDate(dateString, USFormat) {
  var timeStamp = Date.parse(dateString);
  var datePart = dateString;
  if(isNaN(timeStamp)) {
    datePart = dateString?.split(":")[0];
  }
  const date = new Date(datePart);

  const year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : `0${month}`;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : `0${day}`;

  if (USFormat) return `${year}-${month}-${day}`;

  return `${month}/${day}/${year}`;
}

export function FormatDate(dateString) {
  let date = new Date(dateString);
  return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
}
