import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "../axios";
import { SDialog, SRow, SCol, SIcon, SAlert } from "@avalara/skylab-react";
import ValidateDialogSubComponent from "./ValidateDialogSubComponent";
import {
  selectAvataxAccount,
  selectECMSubscription,
  selectCertAccount,
  selectAvataxCompanies,
  selectCertCompanies,
  setAvataxAccount,
  setECMSubscription,
  setCertAccount,
  setAvataxCompanies,
  setCertCompanies,
  selectEcmUsers,
  setEcmUsers,
} from "../App/exportAccountSlice";
import "./export.css";

const ValidateDialog = (props) => {
  const dispatch = useDispatch();
  const [avaTaxId, setAvaTaxId] = useState("");
  const [avaIdError, setAvaIdError] = useState("");

  const [ttrCompanyId, setTtrCompanyId] = useState("");
  const [ttrCompanyIdError, setTtrCompanyIdError] = useState("");

  const [validateClick, setValidationClick] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [disableButton, setDisabledButton] = useState(false);

  const AvataxAccount = useSelector(selectAvataxAccount);
  const EcmSubscription = useSelector(selectECMSubscription);
  const CertAccount = useSelector(selectCertAccount);
  const AvataxCompanies = useSelector(selectAvataxCompanies);
  const CertCompanies = useSelector(selectCertCompanies);
  const ecmUsers = useSelector(selectEcmUsers);
  const [avataxCompaniesCount, setAvataxCompaniesCount] = useState(0);
  const [certCompaniesCount, setCertCaptureCompaniesCount] = useState(0);
  const [missingUsersCount, setMissingUsersCount] = useState(0);

  const checkMissingUsers = async () => {
    dispatch(setEcmUsers("loading"));
    await axios
      .get(
        `//${process.env.REACT_APP_API_HOST}/api/Account/has-missing-users/${avaTaxId}/${ttrCompanyId}`
      )
      .then((response) => {
        if (response.data !== null) {
          setMissingUsersCount(response?.data?.length);
          dispatch(setEcmUsers("complete"));
          setValidationError("success");
        } else {
          dispatch(setEcmUsers("error"));
          setValidationError("No users are found.");
        }
      });
    setDisabledButton(false);
  };

  const checkCertCompanies = async () => {
    dispatch(setCertCompanies("loading"));
    await axios
      .get(
        `//${process.env.REACT_APP_API_HOST}/api/Account/has-missing-certcapture-companies/${avaTaxId}/${ttrCompanyId}`
      )
      .then(async (response) => {
        if (response.data !== null) {
          setCertCaptureCompaniesCount(response.data.length);
          dispatch(setCertCompanies("complete"));
          setValidationError("success");
          await checkMissingUsers();
        } else {
          dispatch(setCertCompanies("error"));
          setValidationError("No CertCapture companies were found.");
        }
      });
    setDisabledButton(false);
  };

  const checkAvataxCompanies = async () => {
    dispatch(setAvataxCompanies("loading"));
    await axios
      .get(
        `//${process.env.REACT_APP_API_HOST}/api/Account/has-missing-avatax-companies/${avaTaxId}/${ttrCompanyId}`
      )
      .then(async (response) => {
        if (response?.data !== null) {
          setAvataxCompaniesCount(response.data.length);
          dispatch(setAvataxCompanies("complete"));
          setValidationError("success");
          await checkCertCompanies();
          return true;
        } else {
          dispatch(setAvataxCompanies("error"));
          setValidationError("No Avatax companies were found.");
          return false;
        }
      });
    setDisabledButton(false);
  };

  const checkCertAccount = async () => {
    dispatch(setCertAccount("loading"));
    await axios
      .get(
        `//${process.env.REACT_APP_API_HOST}/api/Account/has-certcapture-account/${avaTaxId}`
      )
      .then(async (response) => {
        if (response?.data !== null) {
          dispatch(setCertAccount("complete"));
          await checkAvataxCompanies();
          return true;
        } else {
          setValidationError("No CertCapture account was found.");
          dispatch(setCertAccount("error"));
          return false;
        }
      });
    setDisabledButton(false);
  };

  const checkECMSubscription = async () => {
    dispatch(setECMSubscription("loading"));
    await axios
      .get(
        `//${process.env.REACT_APP_API_HOST}/api/Account/has-ecm-subscription/${avaTaxId}`
      )
      .then(async (response) => {
        if (response?.data) {
          dispatch(setECMSubscription("complete"));
          await checkCertAccount();
        } else {
          setValidationError(
            "No ECM Pro or ECM Premium subscription was found."
          );
          dispatch(setECMSubscription("error"));
        }
      });
    setDisabledButton(false);
  };

  const clearState = () => {
    dispatch(setAvataxAccount("initialValue"));
    dispatch(setECMSubscription("initialValue"));
    dispatch(setCertAccount("initialValue"));
    dispatch(setAvataxCompanies("initialValue"));
    dispatch(setCertCompanies("initialValue"));
    dispatch(setEcmUsers("initialValue"));
    setValidationClick(false);
    setValidationError("");
  };

  const handleValidate = async () => {
    clearState();
    let valid = true;

    if (avaTaxId === "") {
      setAvaIdError("error");
      valid = false;
    }

    if (ttrCompanyId === "") {
      setTtrCompanyIdError("error");
      valid = false;
    }

    if (valid) {
      dispatch(setAvataxAccount("loading"));
      setValidationClick(true);
      setDisabledButton(true);
      await axios
        .get(
          `//${process.env.REACT_APP_API_HOST}/api/Account/avatax-account/${avaTaxId}`
        )
        .then(async (response) => {
          if (response?.data) {
            dispatch(setAvataxAccount("complete"));
            await checkECMSubscription();
          } else {
            setValidationError("No Avatax account was found.");
            dispatch(setAvataxAccount("error"));
          }
        });
    }
  };

  const handleReset = async () => {
    clearState();
    setAvaTaxId("");
    setAvaIdError("");
    setTtrCompanyId("");
    setTtrCompanyIdError("");
    setDisabledButton(false);
  };

  const closeDialog = () => {
    props.setIsValidateDialogOpen(false);
    setAvaTaxId("");
    setAvaIdError("");
    setTtrCompanyId("");
    setTtrCompanyIdError("");
    clearState();
    setDisabledButton(false);
  };

  const handleChangeInput = (setter) => {
    return (event) => {
      if (event.target.value) {
        if (event.target.name === "avaTaxId") {
          setAvaIdError("");
        }
      }
      setter(event.target.value);
    };
  };

  return (
    <>
      <SDialog
        id="validateDialog"
        open={props.isValidateDialogOpen}
        onS-dismiss={()=> {props.setIsValidateDialogOpen(false); closeDialog()}}
        aria-modal="true"
      >
        <div slot="header"> Validate Avatax Account</div>
        <div slot="body">
          <SRow>
            <SCol className="pad-bottom-sm">
              <label htmlFor="avaTaxId" className="required">Avatax Account Id</label>
              <input
                id="avaTaxId"
                name="avaTaxId"
                type="text"
                value={avaTaxId}
                onChange={handleChangeInput(setAvaTaxId)}
                className={avaIdError}
              />
              {avaIdError ? (
                <div className="input-msg">
                  <SIcon name="alert-circle-filled" aria-hidden="true" />
                  <span className="top-xs">Avatax Account Id is required</span>
                </div>
              ) : null}
            </SCol>
          </SRow>
          <SRow>
            <SCol className="pad-bottom-sm">
              <label htmlFor="ttrCompanyId" className="required">TTR Company Id</label>
              <input
                id="ttrCompanyId"
                name="ttrCompanyId"
                type="text"
                value={ttrCompanyId}
                onChange={handleChangeInput(setTtrCompanyId)}
                className={ttrCompanyIdError}
              />
              {ttrCompanyIdError ? (
                <div className="input-msg">
                  <SIcon name="alert-circle-filled" aria-hidden="true" />
                  <span className="top-xs">TTR Company Id is required</span>
                </div>
              ) : null}
            </SCol>
          </SRow>
          {validateClick ? (
            <>
              <SRow className="pad-bottom-sm">
                <ValidateDialogSubComponent
                  flag={AvataxAccount}
                  initialText="Avatax Account"
                />
              </SRow>
              <SRow className="pad-bottom-sm">
                <ValidateDialogSubComponent
                  flag={EcmSubscription}
                  initialText="ECM Pro Subscription"
                />
              </SRow>
              <SRow className="pad-bottom-sm">
                <ValidateDialogSubComponent
                  flag={CertAccount}
                  initialText="CertCapture Account"
                />
              </SRow>
              <SRow className="pad-bottom-sm">
                <ValidateDialogSubComponent
                  flag={AvataxCompanies}
                  initialText="Avatax Companies"
                />
              </SRow>
              <SRow className="pad-bottom-sm">
                <ValidateDialogSubComponent
                  flag={CertCompanies}
                  initialText="Certcapture Companies"
                />
              </SRow>
              <SRow className="pad-bottom-sm">
                <ValidateDialogSubComponent
                  flag={ecmUsers}
                  initialText="Users & roles"
                />
              </SRow>
            </>
          ) : null}
          {validationError !== "" ? (
            <SRow>
              <SCol className="pad-left-sm">
                {validationError !== "" && validationError !== "success" ? (
                  <SAlert status="error" noDismiss>
                    <div>{validationError}</div>
                  </SAlert>
                ) : validationError === "success" &&
                  (avataxCompaniesCount !== 0 ||
                    certCompaniesCount !== 0 ||
                    missingUsersCount !== 0) ? (
                  <SAlert status="success" noDismiss>
                    {certCompaniesCount !== 0 ? (
                      <div>
                        {certCompaniesCount > 1
                          ? `Missing ${certCompaniesCount} CertCapture Companies.`
                          : `Missing ${certCompaniesCount} CertCapture Company.`}
                      </div>
                    ) : null}

                    {avataxCompaniesCount !== 0 ? (
                      <div>
                        {avataxCompaniesCount > 1
                          ? `Missing ${avataxCompaniesCount} Avatax Companies.`
                          : `Missing ${avataxCompaniesCount} Avatax Company.`}
                      </div>
                    ) : null}
                    {missingUsersCount !== 0 ? (
                      <div>
                        {missingUsersCount > 1
                          ? `Found ${missingUsersCount} Users.`
                          : `Found ${missingUsersCount} User.`}
                      </div>
                    ) : null}
                  </SAlert>
                ) : null}
              </SCol>
            </SRow>
          ) : null}
          <SRow>
            <SCol className="pad-bottom-none text-center">
              <button
                className="primary center"
                onClick={handleValidate}
                disabled={disableButton}
              >
                Verify
              </button>

              <button className="primary" onClick={handleReset}>
                Reset
              </button>
            </SCol>
          </SRow>
        </div>
        <div slot="footer">
          <button className="secondary right" onClick={closeDialog}>
            Close
          </button>
        </div>
      </SDialog>
    </>
  );
};

export default ValidateDialog;
