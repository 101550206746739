import React from "react";
import { SDialog, SAlert } from "@avalara/skylab-react";
import { DataGrid, gridHeader } from "@certcapture/react-components";

const ExportDetailDialogGrid = (props) => {
	const headerColumns = [
		gridHeader(
			"Company Id",
			"companyId",
			null,
			null,
			null,
			null,
			null,
			null,
			"companyId"
		),
		gridHeader(
			"Company Name",
			"companyName",
			null,
			null,
			null,
			null,
			null,
			null,
			"companyName"
		),
		gridHeader(
			"Certificate Count",
			"certificateCount",
			null,
			null,
			null,
			null,
			null,
			null,
			"certificateCount"
		),
		gridHeader(
			"Customer Count",
			"customerRecordCount",
			null,
			null,
			null,
			null,
			null,
			null,
			"customerRecordCount"
		),
		gridHeader(
			"Vendor Count",
			"vendorRecordCount",
			null,
			null,
			null,
			null,
			null,
			null,
			"vendorRecordCount"
		),
	];

	const getRowKey = (row) => {
		return row.id;
	};

	return (
		<>
			<SDialog
				id="exportDialog"
				open={props.isExportDetailDialogOpen}
				onS-dismiss={() => props.setIsExportDetailDialogOpen(false)}
				aria-modal="true"
			>
				<div slot="header"> Exported Companies Details</div>
				<div slot="body">
					{props?.exportDetail?.length > 0 ? (
						<s-table-container>
							<DataGrid
								columns={headerColumns}
								rows={props.exportDetail}
								inContainer
								getKey={getRowKey}
							/>
						</s-table-container>
					) : (
						<SAlert status="error" id="no-data" noDismiss>
							<div>Sorry, no data available for this export.</div>
						</SAlert>
					)}
				</div>
				<div slot="footer">
					<button
						className="secondary right"
						onClick={() => {
							props.setIsExportDetailDialogOpen(false);
						}}
					>
						Cancel
					</button>
				</div>
			</SDialog>
		</>
	);
};

export default ExportDetailDialogGrid;
